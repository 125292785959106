<template>
  <div class="main">
    <em>订单详情</em>
    <div class="cont">
      <el-row>
        <el-col :span="8"><div class="l-title">订单号：</div></el-col>
        <el-col :span="16"><div class="r-cont">2327348348454545</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">订单状态：</div></el-col>
        <el-col :span="16"><div class="r-cont">交易关闭</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">商品名称：</div></el-col>
        <el-col :span="16"
          ><div class="r-cont">
            2023年 燃气企业主要负责人新取证培训（第一期）
          </div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">商品价格：</div></el-col>
        <el-col :span="16"><div class="r-cont">¥9844</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">订单价格：</div></el-col>
        <el-col :span="16"><div class="r-cont">¥3444</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">创建时间：</div></el-col>
        <el-col :span="16"><div class="r-cont">2023-1-10 10:41:51</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">退款有效期：</div></el-col>
        <el-col :span="16"><div class="r-cont">无</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">关闭时间：</div></el-col>
        <el-col :span="16"><div class="r-cont">2023-1-10 10:41:51</div></el-col>
      </el-row>
      <div class="btn-wrap">
        <el-button
          type="primary"
          style="width: 120px; margin-right: 50px"
          @click="btnBack"
          >返回</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderDetail } from "@/api/order";
export default {
  name: "OrderDetail",
  data() {
    return {};
  },
  mounted() {
    this.getOrderDetail();
  },
  methods: {
    getOrderDetail() {
      console.log(this);
      getOrderDetail(this.$route.query.id).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    btnBack() {
      this.$router.back(-1)
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  em {
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .cont {
    .l-title {
      margin: 20px 0;
      text-align: right;
      color: #666;
    }
    .r-cont {
      color: #333;
      margin: 20px 0;
      text-align: left;
    }
    .btn-wrap {
      margin-top: 20px;
    }
  }
}
</style>